<template>
  <ul class="menu-nav">
    <!-- START CORE-->
    <li class="menu-section">
      <h4 class="menu-text">CORE</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>
    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon2-digital-marketing"></i>
        <span class="menu-text">Mantenimientos</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">Mantenimientos</span>
            </span>
          </li>

          <!--<editor-fold desc="User">-->
          <router-link
            :to="{ name: 'user_list' }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon2-user"></i>
                <span class="menu-text">Usuarios</span>
              </a>
            </li>
          </router-link>
          <!--</editor-fold>-->

          <!--<editor-fold desc="Product">-->
          <router-link
            :to="{ name: 'core_product_list' }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon2-open-box"></i>
                <span class="menu-text">Productos</span>
              </a>
            </li>
          </router-link>
          <!--</editor-fold>-->

          <!--<editor-fold desc="MachineId">-->
          <router-link
            :to="{ name: 'core_machine_id_create' }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon2-open-box"></i>
                <span class="menu-text">Series</span>
              </a>
            </li>
          </router-link>
          <!--</editor-fold>-->

          <!--<editor-fold desc="Group">-->
          <router-link
            :to="{ name: 'core_group_list' }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon2-open-box"></i>
                <span class="menu-text">Grupos</span>
              </a>
            </li>
          </router-link>
          <!--</editor-fold>-->

          <router-link
            :to="{ name: 'core_brand_list' }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon2-layers"></i>
                <span class="menu-text">Marcas</span>
              </a>
            </li>
          </router-link>

          <router-link
            :to="{ name: 'core_category_list' }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon2-architecture-and-city"></i>
                <span class="menu-text">Categoría</span>
              </a>
            </li>
          </router-link>

          <router-link
            :to="{ name: 'person' }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon2-architecture-and-city"></i>
                <span class="menu-text">Personas</span>
              </a>
            </li>
          </router-link>

          <router-link
            :to="{ name: 'core_warehouse_list' }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon2-architecture-and-city"></i>
                <span class="menu-text">Direcciones</span>
              </a>
            </li>
          </router-link>

          <router-link
            :to="{ name: 'core_item_warehouse_list' }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon2-architecture-and-city"></i>
                <span class="menu-text">Stock</span>
              </a>
            </li>
          </router-link>

          <router-link
            :to="{ name: 'core_vehicle_list' }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon2-architecture-and-city"></i>
                <span class="menu-text">Vehículo</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>
    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon2-digital-marketing"></i>
        <span class="menu-text">Ventas</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">Ventas</span>
            </span>
          </li>

          <!--<editor-fold desc="Lista de ventas">-->
          <router-link
            :to="{ name: 'core_sales_list' }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon2-user"></i>
                <span class="menu-text">Lista de ventas</span>
              </a>
            </li>
          </router-link>
          <!--</editor-fold>-->

          <!--<editor-fold desc="Crear ventas">-->
          <router-link
            :to="{ name: 'core_sales_create' }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon2-open-box"></i>
                <span class="menu-text">Crear venta</span>
              </a>
            </li>
          </router-link>
          <!--</editor-fold>-->

          <!--<editor-fold desc="Lista de guías de remisión">-->
          <router-link
            :to="{ name: 'core_dispatch_list' }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon2-open-box"></i>
                <span class="menu-text">Lista guías remisión</span>
              </a>
            </li>
          </router-link>
          <!--</editor-fold>-->
        </ul>
      </div>
    </li>
    <!-- END   CORE-->

    <router-link
      :to="{ name: 'core_buy_list' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-architecture-and-city"></i>
          <span class="menu-text">Compras</span>
        </a>
      </li>
    </router-link>

    <li class="menu-section">
      <h4 class="menu-text">Logistica</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <router-link
      :to="{ name: 'logistics_dispatch_list' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-architecture-and-city"></i>
          <span class="menu-text">Envíos</span>
        </a>
      </li>
    </router-link>

    <li class="menu-section">
      <h4 class="menu-text">Tienda</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <router-link
      :to="{ name: 'store_buy_list' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-architecture-and-city"></i>
          <span class="menu-text">Compras</span>
        </a>
      </li>
    </router-link>
  </ul>
</template>

<script>
export default {
  name: "KTMenu",
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
  },
};
</script>
